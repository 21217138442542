<div class="limit-edit-block">
    <ng-container *ngIf="!isEditing">
        <span class="limit-edit-block_limit">{{ usedLimit }} / {{ userLimit }} кк</span>
    </ng-container>

    <ng-container *ngIf="!isEditing">
        <i class="pi pi-plus-circle --icon-color-hover" (click)="isEditing = true"></i>
    </ng-container>

    <ng-container *ngIf="isEditing">
        <input
            type="number"
            class="limit-edit-block_input"
            name="{{ id }}"
            [required]="true"
            [(ngModel)]="userLimit"
            (keyup.enter)="sendNewUserLimit()" />

        <i class="pi pi-check --icon-color-hover" (click)="sendNewUserLimit()"></i>
    </ng-container>
</div>
