export enum ToastMessageText {
    AccessDenied = "В доступе отказано",
    SessionFailed = "Перезайдите в систему",
    SucceededDataUpdate = "Данные обновлены",
    SucceededSave = "Успешное сохранение",
    SucceedRegistration = "Пользователь успешно зарегистрирован",
    SucceedPasswordRestoring = "Пароль успешно восстановлен",
    CEOChangedSuccessfully = "Директор компании успешно заменен",
    KPPChangedSuccessfully = "КПП компании успешно заменен",
    SucceededChangeAlias = "Алиас успешно изменен",
    SucceededChangeLimit = "Лимит пользователя изменен",
    SucceededChangeCompany = "Замена компании произведена успешно",
    SucceededChangeDirectory = "Директор компании успешно заменен",
    SucceededChangeKpp = "КПП компании успешно заменен",
    SucceededDeletedRow = "Строка успешно удалена",
    SucceededUpdateRow = "Позиция успешно изменена",
    SucceededRemovedRequest = "Заявка успешно удалена",
    SucceededNextCheckDateChange = "Дата следующей проверки сохранена",
    SucceededUploadFile = "Файл загружен",
    FileDownloadedSuccessfully = "Файл успешно скачан",
    CustomReplacementAddedSuccessfully = "Индивидуальная замена успешно добавлена",
    InvitationCopied = "Инвайт скопирован",
    ErrorUnknown = "Неизвестная ошибка",
    LoginFailed = "Ошибка входа",
    ErrorPeriod = "Указанный период не должен превышать год",
    WrongFileFormat = 'Поддерживаются только файлы в формате "xlsx, xls"',
    InfoUnknown = "Что-то пошло не так",
    ErrorCheckerUpload = "Ошибка при загрузке файла",
    SalesStopped = "Продажи приостановлены",
    SalesActivated = "Продажи активированы",
    FlagCleared = "Отметка о новых заявках сброшена",
    TokenSuccessfullyCopied = "Токен скопирован",
    TariffSuccessfullyUpdated = "Тариф успешно изменен",
}

export enum ConfirmationServiceText {
    AquariumsRemoveFirm = "Данное действие приведет к удалению всех фирм. Данное действие необратимо. Хотите продолжить?",
    BooksCancelDownload = `<strong>Подтвердите действие</strong><br/>Вы уверены, что хотите прервать скачивание?`,
    UsersPartnerModal = `<strong>Подтвердите действие</strong><br/>Данное действие приведет к удалению батрака и всех его холопов. Вы уверены?`,
    UsersSlaveModal = `<strong>Подтвердите действие</strong><br/>Данное действие приведет к удалению холопа. Вы уверены?`,
    BooksDeleteFile = `<strong>Подтвердите действие</strong><br/>Данное действие удалит все клиентские заявки. Вы уверены?`,
    ControlRemoveRow = "Хотите удалить строку?",
    ControlRemoveRows = "Хотите удалить строки?",
    ControlRemoveRequest = `<strong>Подтвердите действие</strong><br/>Данное действие удалит всю заявку целиком. Вы уверены?`,
    BreachConfirm = "Вы уверены, что хотите заменить продавца на",
    BreachLostBack = "Данное действие удалит фирму из Потери контроля (ЭП). Вы уверены?",
    BreachUploadLoss = `Вы уверены, что хотите загрузить список <span class='red-text'>Потеря контроля</span>`,
    BreachUploadCancel = `Вы уверены, что хотите загрузить список <span class='orange-text'>Аннулированные</span>`,
    BreachUploadLostBack = `Вы уверены, что хотите загрузить список <span class='red-text'>Потеря контроля (ждем новую ЭП)</span>`,
    BreachUploadInBottom = `Вы уверены, что хотите загрузить список <span class='red-text'>Разрыв в низах</span>`,
    BreachRemoveCancellation = `<strong>Подтвердите действие</strong><br/>Информация об Аннулировании будет удалена до следующего выявленного Аннулирования.<br/>Вы уверены?`,
    BreachRemoveLost = `<strong>Подтвердите действие</strong><br/>Информация о Потере контроля будет удалена и больше выводиться не будет.Ваш разрыв не будет устранен.<br/>Вы уверены?`,
    BreachRemoveLostBack = `<strong>Подтвердите действие</strong><br/>Информация о Потере контроля (ждём новую ЭП) будет удалена и больше выводиться не будет<br/>Вы уверены?`,
    BreachRemovePersonalSubstitutions = `<strong>Подтвердите действие</strong><br/>Информация об индивидуальной замене будет удалена и больше выводиться не будет.<br/>Вы уверены?`,
    BreachRemovePersonalSubstitutionsFirm = `<strong>Подтвердите действие</strong><br/>Данное действие удалит фирму из Индивидуальной замены (ИЗ).<br/>Вы уверены?`,
    DressingStopSales = `<strong>Подтвердите действие</strong><br/>Приостановить продажи Уточненок во всех периодах?`,
    DressingStartSales = `<strong>Подтвердите действие</strong><br/>Запустить продажи Уточненок во всех периодах?`,
    DressingCloseTheCompany = `<strong>Подтвердите действие</strong><br/>Вы уверены, что фирма сдана?`,
    InvoicesEditLevelTpl = `<strong class='mb-1'>Подтвердите действие</strong><br/>`,
}

export enum SearchFieldPlaceholderText {
    Password = "Пароль",
    LoginOrEmail = "Логин или электронная почта",
    YourEmail = "Ваша электронная почта",
    CreateLogin = "Придумайте логин",
    CreatePassword = "Придумайте пароль",
    EnterPassword = "Введите пароль",
    ConfirmPassword = "Подтвердите пароль",
    Invitation = "Инвайт",

    User = "Пользователь",
    AllUsers = "Все пользователи",
    GroupName = "Имя группы",
    BreachType = "Тип разрыва",
    AllPeriods = "Все периоды",
    AllCompanies = "Все компании",

    ChooseCompany = "Выберите компанию",
    ChooseCompanySeller = 'Выберите "Компанию-продавца"',
    ChooseUser = "Выберите пользователя",
    ChooseCouple = "Выберите пару",
    ChooseOrderNum = "Выбор номера заявки",

    DefineDate = "Укажите дату",

    EnterDate = "Введите дату",
    EnterSum = "Введите сумму",
    EnterName = "Введите название",
    EnterNickname = "Введите ник",
    EnterInn = "Введите ИНН",
    EnterNameOrInn = "Введите название или ИНН",

    SearchUser = "Поиск пользователя",
    SearchByUser = "Поиск по пользователю",
    SearchCompany = "Поиск компании",
    SearchByCompany = "Поиск по компании",
    SearchByOrder = "Поиск по номеру заявки",
    SearchByInnAndName = "Поиск по ИНН/названию",
    SearchByOkved = "Поиск по ОКВЭД",
    SearchBySeller = "Поиск по покупателю",
    SearchByBuyer = "Поиск по продавцу",
    SearchByNameAndOrder = "Поиск по нику и номеру заявки",

    TariffType = "Тип тарифа",
}
