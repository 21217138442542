<div class="header-ruts flex w-full align-items-end">
    <div class="c-page c-page-header flex align-items-end justify-content-between">
        <ng-container *ngIf="(currentUser$ | async) && !(isLoading$ | async); else empty">
            <ng-container *ngIf="swd.to(1080, true); else desktopView">
                <div class="flex flex-row small-width-block align-items-center">
                    <a [routerLink]="['/']"><i class="ruts-icon" [ngClass]="logoImg"></i></a>
                    <ng-container *ngIf="!swd.to(680, true) || !userInfoIsOpen || swd.to(480)">
                        <ng-container [ngTemplateOutlet]="periodBlock" />
                        <ng-container [ngTemplateOutlet]="limitBlock" />
                    </ng-container>
                </div>
                <div class="flex flex-row align-items-center">
                    <ng-container [ngTemplateOutlet]="exitBlock" />
                    <button
                        class="header_burger"
                        icon="pi pi-bars"
                        iconPos="left"
                        pButton
                        type="button"
                        *ngIf="!swd.isDesktop()"
                        (click)="openMenu()"></button>
                </div>
                <ng-container [ngTemplateOutlet]="controlsBlock" />
            </ng-container>
            <ng-template #desktopView>
                <ng-container *ngIf="!swd.isDesktop()" >
                    <a  [routerLink]="['/']"><i class="ruts-icon" [ngClass]="logoImg"></i></a>
                </ng-container>
                <ng-container [ngTemplateOutlet]="periodBlock" />
                <ng-container [ngTemplateOutlet]="controlsBlock" />
                <div class="flex flex-row align-items-end exit-right-side">
                    <ng-container [ngTemplateOutlet]="limitBlock" />
                    <ng-container [ngTemplateOutlet]="exitBlock" />
                    <button
                        class="header_burger"
                        icon="pi pi-bars"
                        iconPos="left"
                        pButton
                        type="button"
                        *ngIf="!swd.isDesktop()"
                        (click)="openMenu()"></button>
                </div>
            </ng-template>

            <ng-container
                *ngIf="swd.to(1080, true) && (selectionPeriodPanelIsOpen || userInfoIsOpen)">
                <ng-container *ngIf="currentUser$ | async as currentUser">
                    <div
                        class="header_actions-background"
                        [ngClass]="{singleItem: currentUser.isAdmin}"
                        [class.--h-exit]="userInfoIsOpen"
                        [class.--h-period]="selectionPeriodPanelIsOpen">
                        <div class="flex justify-content-center align-items-end h-full"
                             (swipeup)="hideAllPopup()">
                                <span class="close-icon"></span>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-template #empty>
            <div class="header_skeleton">
                <p-skeleton />
                <p-skeleton />
                <p-skeleton />
            </div>
        </ng-template>
    </div>
</div>

<ng-template #limitBlock>
    <ng-container *ngIf="currentUser$ | async as currentUser">
        <ng-container *ngIf="!currentUser.isAdmin && !currentUser.isInexperienced">
            <ng-container *ngIf="isUpdatingLimits$ | async; else limitsView">
                <p-skeleton class="w-full mt-1" borderRadius="4px" height="20px" />
            </ng-container>

            <ng-template #limitsView>
                <ng-container *ngIf="period$ | async as period">
                    <div class="header_exit_limits">
                        <p>Лимиты:</p>
                        <div>
                            {{ currentUser.usedLimit }} кк / {{ currentUser.userLimit }} кк
                        </div>
                    </div>
                </ng-container>
            </ng-template>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #exitBlock>
    <ng-container *ngIf="currentUser$ | async as currentUser">
        <div
            class="header_exit flex flex-column"
            [ngClass]="{ hide: periodViewShown || !userInfoIsOpen || swd.to(479, true) }"
            (click)="togglePeriodView(HeaderTypeOfInformationShown.Info)">
            <span></span>
            <div class="flex flex-row">
                <div class="header_exit_left">
                    <i class="pi pi-user text-blue-900 "></i>
                    <span class="header_exit-username">{{ currentUser.userName }}</span>
                </div>
                <div class="header_exit_right">
                <span class="header_exit-out" (click)="exit()">
                    Выход
                </span>
                </div>
            </div>
        </div>
        <div class="absolute w-full user-info-for-mobile"
             [ngClass]="{singleItem: currentUser.isAdmin}"
             *ngIf="userInfoIsOpen && swd.to(479, true)">
            <div class="flex justify-content-between align-items-center user-info-for-mobile-exit">
                <div class="left">
                    <i class="pi pi-user text-blue-900"></i>
                    <span>{{ currentUser.userName }}</span>
                </div>
                <span (click)="exit()">
                            Выход
                </span>
            </div>
            <div class="flex align-items-center user-info-for-mobile-limits"
                 *ngIf="!currentUser.isAdmin && !currentUser.isInexperienced">
                <ng-container *ngIf="isUpdatingLimits$ | async; else limitsView">
                    <p-skeleton class="w-full mt-1" borderRadius="4px" height="20px" />
                </ng-container>

                <ng-template #limitsView>
                    <p>Лимиты:</p>
                    <div class="w-full inline-flex justify-content-center align-items-center text-center">
                        {{ currentUser.usedLimit }} кк /{{ currentUser.userLimit }} кк
                    </div>
                </ng-template>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #controlsBlock>
    <ng-container *ngIf="swd.to(680, true)">
        <div class="header_controls flex-column" *ngIf="swd.isDesktop() || selectionPeriodPanelIsOpen">
            <div class="w-full flex flex-row">
                <div class="flex flex-column w-6 align-items-start height-70px">
                    <p>
                        Квартал:
                    </p>
                    <ruts-quarter-radio [(ngModel)]="quarter" />
                </div>
                <div class="flex flex-column w-6 align-items-start height-70px">
                    <p>
                        Год:
                    </p>
                    <ruts-year-input [(ngModel)]="yearInput" />
                </div>
            </div>
            <div>
                <p *ngIf="!swd.to(768, true)"><br /></p>
                <button
                    label="Применить"
                    pButton
                    type="button"
                    (click)="setNewPeriod()"></button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!swd.to(680, true) || swd.to(479, true)">
        <div class="header_controls" *ngIf="!swd.to(1080, true) || selectionPeriodPanelIsOpen">
            <div>
                <p>
                    Квартал:
                </p>
                <ruts-quarter-radio [(ngModel)]="quarter" />
            </div>
            <div>
                <p>
                    Год:
                </p>
                <ruts-year-input [(ngModel)]="yearInput" />
            </div>
            <div>
                <p *ngIf="!swd.to(768, true)"><br /></p>
                <button
                    label="Применить"
                    pButton
                    type="button"
                    (click)="setNewPeriod()"></button>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #periodBlock>
    <ng-container *ngIf="currentUser$ | async as currentUser">
        <div
            class="header_period"
            [ngClass]="{ admin: currentUser.isAdmin, hide: !periodViewShown }"
            (click)="togglePeriodView(HeaderTypeOfInformationShown.Period)">
            <div class="header_period-value">
                <ng-container *ngIf="period$ | async as period">
                    <div class="text-left">
                        <ng-container *ngIf="swd.to(1080, true); else fullPeriodText">
                            <span>Период:</span>
                        </ng-container>
                        <ng-template #fullPeriodText>
                            <span class="text-xs white-space-nowrap">Выбранный период:</span>
                        </ng-template>
                    </div>
                    <div>{{ period.quarter }}кв{{ period.year | sliceYear }}</div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-template>
