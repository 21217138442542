import { Injectable, NgModule } from "@angular/core";
import {
    HAMMER_GESTURE_CONFIG,
    HammerGestureConfig,
    HammerModule,
} from "@angular/platform-browser";

import * as Hammer from "hammerjs";

@Injectable()
export class HammerConfig extends HammerGestureConfig {
    overrides = {
        swipe: { direction: Hammer.DIRECTION_ALL },
    };
}

@NgModule({
    declarations: [],
    imports: [HammerModule],
    exports: [HammerModule],
    providers: [
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerConfig,
        },
    ],
})
export class RutsHammerModule {}
