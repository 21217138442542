import { getQuarter, getYear, sub } from "date-fns";

import { UserRole } from "@core/interfaces/core.interface";
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { IPeriod } from "@core/interfaces/period.interface";

export type PrimitiveType = boolean | string | number;

const targetDate = sub(new Date(), { months: 3 });
const targetYear = getYear(targetDate);

export enum DefaultsEnum {
    quarter = getQuarter(targetDate),
    year = targetYear,
    minYear = targetYear - 5,
    maxYear = targetYear + 1,
}

export const DefaultPeriod: IPeriod = {
    quarter: DefaultsEnum.quarter,
    year: DefaultsEnum.year,
};

export enum Role {
    isAdmin = "Admin",
    isKulak = "Partners",
    isAdvanced = "Advanced user",
}

export enum PageName {
    Auth = "auth",
    AuthInvites = "invite",
    Registration = "registration",
    RestorePassword = "restore-password",
    Users = "users",
    Invites = "invites",
    Aquariums = "aquariums",
    External = "external",
    Books = "books",
    Checker = "checker",
    Control = "control",
    SubPartners = "subPartners",
    Dressing = "dressing",
    DressingAdminMain = "main",
    DressingAdminTochno = "tochno",
    Documents = "documents",
    Invoice = "invoice",
    Breach = "breach",
    BreachCancellation = "nulled",
    BreachReplacement = "replacement",
    BreachLostBack = "lost-back",
    BreachInBottom = "in-bottom",
    BreachPro = "pro",
    PersonalSubstitutions = "personal-substitution",
    BreachLossOfControl = "lost",
    Archive = "archive",
    Chains = "chains",
    NotFound = "404",
    Empty = "empty",
    Maintenance = "maintenance",
}

export enum GenerationType {
    LM = "generation-lm",
    Unknown = "unknown",
    Deuce = "generation-deuce",
    Fiver = "generation-fiver",
    Journals = "generation-journals",
    Kvo43 = "generation-kvo43",
    L1000 = "generation-l1000",
    Twelfth = "generation-negative-book",
    L1000NoL = "generation-l1000-no-l",
    Intermediary = "generation-intermediary",
    PiPlus = "pBased",
}

export enum ChainStatus {
    NotGenerated = "notGenerated",
    PartlyGenerated = "partlyGenerated",
}

export const userRoleKey: { [key in UserRole]: keyof ICurrentUser } = {
    admin: "isAdmin",
    kulak: "isKulak",
    holop: "isHolop",
    batrak: "isBatrak",
    inexperienced: "isInexperienced",
};

export enum TariffType {
    Elite = "elite",
    Premium = "premium",
    Standard = "standard",
}
