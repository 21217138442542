import { DOCUMENT } from "@angular/common";
import { inject, Injectable, NgZone } from "@angular/core";

import { fromEvent, share, startWith, throttleTime } from 'rxjs';
import { map } from "rxjs/operators";

enum BREAKPOINTS {
    xs = 480,
    sm = 680,
    md = 768,
    lg = 992,
    xl = 1381,
}

@Injectable()
export class ScreenWidthDetectorService {
    private document = inject(DOCUMENT);
    private zone = inject(NgZone);

    get width(): number {
        return this.document.defaultView?.innerWidth || 0;
    }

    width$ = this.zone.runOutsideAngular(() =>
        fromEvent(this.document.defaultView!, "resize").pipe(
            throttleTime(300),
            map(() => this.width),
            startWith(this.width),
            share()
        )
    );

    from(point: number | keyof typeof BREAKPOINTS, include: boolean = true): boolean {
        point = typeof point === "number" ? point : BREAKPOINTS[point];

        return include ? point <= this.width : point < this.width;
    }

    to(point: number | keyof typeof BREAKPOINTS, include: boolean = false): boolean {
        point = typeof point === "number" ? point : BREAKPOINTS[point];

        return include ? point >= this.width : point > this.width;
    }

    isDesktop(): boolean {
        return this.from("xl");
    }

    isXs(): boolean {
        return this.to("xs");
    }
}
