import { DOCUMENT } from "@angular/common";
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Inject,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { HammerModule } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";

import { debounceTime, fromEvent, Observable, throttleTime } from "rxjs";
import { filter } from "rxjs/operators";

import { Actions, Select, Store } from "@ngxs/store";

import { Period } from "@core/classes/Period.class";
import { SubscriberComponent } from "@core/classes/subscriber.class";
import { HeaderTypeOfInformationShown } from "@core/components/header/enums/header.enum";
import { getShortYear, hasActionsRunning } from "@core/helpers/handlers.helper";
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { IPeriod } from "@core/interfaces/period.interface";
import { ScreenWidthDetectorService } from "@core/services/screen-width-detector.service";
import { GlobalActions } from "@core/store/core.actions";
import { CoreStateSelector } from "@core/store/core.selectors";
import { CoreState } from "@core/store/core.state";

import { environment } from "../../../../environments/environment";
import { AuthPageActions } from "../../../auth-page/store/auth.actions";

@Component({
    selector: "ruts-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent extends SubscriberComponent implements OnInit {
    quarter: number;
    yearUI: number;
    yearInput: number;
    blackOutActive: boolean = false;
    periodViewShown: boolean = false;
    userInfoIsOpen: boolean = false;
    selectionPeriodPanelIsOpen: boolean;
    HeaderTypeOfInformationShown = HeaderTypeOfInformationShown;
    logoImg: string;

    @Select(CoreState.getPeriod) period$: Observable<IPeriod>;
    @Select(CoreState.getUser) currentUser$: Observable<ICurrentUser>;

    isLoading$: Observable<boolean>;
    isUpdatingLimits$: Observable<boolean>;

    constructor(
        private readonly _cdr: ChangeDetectorRef,
        private readonly _actions$: Actions,
        private readonly _store: Store,
        private readonly _router: Router,
        private readonly _eRef: ElementRef,
        private readonly _zone: NgZone,
        public readonly swd: ScreenWidthDetectorService,
        @Inject(DOCUMENT) private readonly _document: Document
    ) {
        super();
        this.logoImg = environment.logo;
    }

    @HostListener("document:click", ["$event"])
    clickOutside(event: any): void {
        if (!this._eRef.nativeElement.contains(event.target)) {
            this.hideAllPopup();
        }
    }

    ngOnInit(): void {
        this.isLoading$ = hasActionsRunning(this._actions$, [GlobalActions.AppInit]);
        this.isUpdatingLimits$ = hasActionsRunning(this._actions$, [
            GlobalActions.SetActivePeriod,
            GlobalActions.UpdateLimits,
        ]);

        this._zone.runOutsideAngular(() =>
            this.addSub(
                fromEvent(window, "resize")
                    .pipe(throttleTime(300))
                    .subscribe(() => this._zone.run(() => (this.swd.width = window.innerWidth)))
            )
        );

        this.addSub([
            this._store
                .select(CoreStateSelector.getActivePeriod)
                .pipe(
                    filter(
                        period =>
                            !Period.areEqualPeriods(period, {
                                quarter: this.quarter,
                                year: this.yearInput,
                            })
                    ),
                    debounceTime(500)
                )
                .subscribe(period => {
                    this.yearInput = period.year;
                    this.yearUI = getShortYear(period.year);
                    this.quarter = period.quarter;
                    this._cdr.detectChanges();
                }),
            this._router.events
                .pipe(filter(e => e instanceof NavigationEnd))
                .subscribe(() => this.hideAllPopup()),
        ]);
    }

    openMenu() {
        const menu = this._document.querySelector(".ruts-menu");

        menu?.classList.add("active");

        this.blackOutActive = true;
    }

    openChoosePeriod() {
        this.blackOutActive = true;
        this.userInfoIsOpen = false;
        this.selectionPeriodPanelIsOpen = true;
    }

    openUserInfo() {
        this.blackOutActive = true;
        this.selectionPeriodPanelIsOpen = false;
        this.userInfoIsOpen = true;
    }

    togglePeriodView(field: HeaderTypeOfInformationShown) {
        if (field === HeaderTypeOfInformationShown.Period) {
            this.periodViewShown = true;

            return this.openChoosePeriod();
        }

        if (field === HeaderTypeOfInformationShown.Info) {
            this.periodViewShown = false;
            if (!this.userInfoIsOpen) {
                return this.openUserInfo();
            }
            this.userInfoIsOpen = false;
        }
    }

    hideAllPopup() {
        const menu = this._document.querySelector(".ruts-menu");

        this.blackOutActive = false;
        this.userInfoIsOpen = false;
        this.periodViewShown = false;
        this.selectionPeriodPanelIsOpen = false;

        menu?.classList.remove("active");
    }

    setNewPeriod() {
        this.hideAllPopup();

        this._store.dispatch(
            new GlobalActions.SetActivePeriod(
                Period.create({ quarter: this.quarter, year: this.yearInput })
            )
        );
    }

    exit() {
        this._store.dispatch(new AuthPageActions.Logout());
    }
}
