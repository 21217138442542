import { APP_BASE_HREF } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";
import { NgxsModule } from "@ngxs/store";

import { MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";

import { NgxsResetPluginModule } from "ngxs-reset-plugin";

import { HeaderModule } from "@core/components/header/header.module";
import { MenuComponentModule } from "@core/components/menu/menu.module";
import { CoreModule } from "@core/core.module";
import { AuthLayoutComponent } from "@core/layouts/auth-layout/auth-layout.component";
import { SiteLayoutComponent } from "@core/layouts/site-layout/site-layout.component";
import { RutsHammerModule } from "@core/modules/hammer/hammer.module";
import { ErrorInterceptor } from "@core/services/interceptors/error.interceptor";
import { ScreenWidthDetectorService } from "@core/services/screen-width-detector.service";
import { CoreState } from "@core/store/core.state";

import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthModule } from "./auth-page/auth.module";
import { MaintenanceComponent } from "./maintenance/maintenance.component";
import { NotFoundComponent } from "./not-found/not-found.component";

@NgModule({
    declarations: [
        AppComponent,
        AuthLayoutComponent,
        SiteLayoutComponent,
        NotFoundComponent,
        MaintenanceComponent,
    ],
    imports: [
        CoreModule,
        BrowserModule,
        AppRoutingModule,
        AuthModule,
        ToastModule,
        HeaderModule,
        MenuComponentModule,
        BrowserAnimationsModule,
        NgxsModule.forRoot([CoreState], {
            developmentMode: !environment.production,
        }),
        // NgxsActionsExecutingModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsRouterPluginModule.forRoot(),
        NgxsResetPluginModule.forRoot(),
        RutsHammerModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: APP_BASE_HREF,
            useValue: "/",
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        MessageService,
        ScreenWidthDetectorService,
    ],
})
export class AppModule {}
