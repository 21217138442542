import { Pipe, PipeTransform } from "@angular/core";

import { getShortYear } from "@core/helpers/handlers.helper";
import { Nulled } from "@core/interfaces/core.interface";

type UserWithAdmin = Nulled<{
    isAdmin?: boolean;
}>;

@Pipe({
    name: "sliceYear",
    standalone: true,
    pure: true,
})
export class SliceYearPipe implements PipeTransform {
    transform(value: number): number {
        return getShortYear(value);
    }
}
